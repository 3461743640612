import moment from 'moment'
import * as backend from './build/index.main.mjs'

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
let algoexplorer
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
  algoexplorer = 'https://algoexplorer.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
  algoexplorer = 'https://testnet.algoexplorer.io'
}

export const getExplorer = () => algoexplorer

/*
 * Compute the the days, hours, minutes, and seconds between two moments
 * @param {Object} momA The first moment
 * @param {Object} momB The seconds moment
 * @param {Array} Days, hours, minutes, and seconds betweentwo moments 
 */
export const getMomentDiff = (aMom) => {
  let mom = [
    ['days', 0],
    ['hours', 0],
    ['minutes', 0],
    ['seconds', 0]
  ]
  let diff = mom.map(([head,]) => head).map((el, index) => {
    let moms = mom.slice(0, index)
    let period = aMom.diff(
      moms.reduce((acc, [name, val]) => {
        return acc.add(val, name)
      }, moment()), el)
    mom[index] = [el, period]
    //console.log({mom, moms, period})
    return [el, period]
  })
  //console.log({diff})
  return diff
}

export const formatCompactAddress = (address) =>
  String(address).substr(0, 5).concat("...").concat(String(address).substr(-5))

export const zip = (a, b) => a.map((k, i) => [k, b[i]])

const somethingFromSome = (f, d) => (some) => some[1] ? f(some[1]) : d

export const formatCurrencyFromSome = (stdlib, some, decimal = 4) =>
  somethingFromSome((some) => stdlib.formatCurrency(some, decimal), 0)(some)

export const bigNumberToNumberFromSome = (stdlib, some) =>
  somethingFromSome((some) => stdlib.bigNumberToNumber(some), 0)(some)

export const formatAddressFromSome = (stdlib, some) =>
  somethingFromSome((some) => stdlib.formatAddress(some), 0)(some)

export const getUrlPath = (url) =>
  ((delimeter) =>
    url.split(delimeter).slice(2).join(delimeter))
    ('/')

export const getAssetTest =
  async (assetIndex) => {
    let asset = {}
    try {
      let res = await getAsset(assetIndex)
      if (res.status === 200) {
        asset = res.data
      }
    } catch (e) { }
    return asset
  }

export const getAccountInfo = async (addr) => {
  return (
    await axios.get(`${algoexplorerapi_endpoint}/v2/accounts/${addr}`)
  )?.data;
};
export const getAccInfo =
  async (address) =>
    await axios.get(`${algoexplorerapi_endpoint}/v2/accounts/${address}`)

export const getAsset =
  async (assetIndex) =>
    await axios.get(`${algoexplorerapi_endpoint}/v1/asset/${assetIndex}`)

export const getAsset2 = (assetIndex) =>
  axios.get(`${algoexplorerapi_endpoint}/v1/asset/${assetIndex}`)

export const getAppInfo =
  async (appId) =>
    await axios.get(`${algoexplorerapi_endpoint}/v2/applications/${appId}`)

export const getAppInfo2 =
  (appId) =>
    axios.get(`${algoexplorerapi_endpoint}/v2/applications/${appId}`)

export const getBlock = (block) =>
  axios.get(`${algoexplorerapi_endpoint}/idx2/v2/blocks/${block}`)

export const getNodeStatus = async () =>
  await axios.get(`${algoexplorerapi_endpoint}/v2/status`)

export const getAppTransactions = async (appId) =>
  await axios.get(`${algoexplorerapi_endpoint}/idx2/v2/transactions?application-id=${appId}`)

export const getAccountTransaction = async (addr, params) =>
  await axios.get(`${algoexplorerapi_endpoint}/idx2/v2/accounts/${addr}/transactions`, { params })

export const getCFIPFSUrl = (path) =>
  `https://cloudflare-ipfs.com/ipfs/${path}`

export const getCFIPFS =
  async (path) =>
    await axios.get(getCFIPFSUrl(path))

export const accountHasAsset = async (addr, assetId) => {
  let accInfo = (await getAccInfo(addr))?.data || {}
  let { assets } = accInfo
  let res = accInfo.assets.filter(el => el['asset-id'] === assetId)
  return res.length !== 0
}

export const placeholderImage = 'https://via.placeholder.com/1024'

export const fetchAssetImage =
  async (assetIndex) => {
    let image
    let contentType
    try {
      const asset = (await getAsset(assetIndex))?.data
      let url = asset?.url
      if (url === undefined) {
        return placeholderImage
      }
      if (url.indexOf('ipfs://') === 0) {
        return 'https://ipfs.io/ipfs/' + url.split('/').slice(2).join('/')
      }
      console.log({ url })
      let res = await axios.get(url)
      contentType = res?.headers['content-type'] ?? ""
      console.log({ contentType })
      switch (contentType) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
          image = url
          return image
        case 'text/json':
        default:
          break
      }
      if (url.indexOf('ipfs') !== -1) {
        if (url.indexOf('json') !== -1) {
          // url is metadata json with relative path to image
          const maybeImage = (await getCFIPFS(getUrlPath(url))).data
          var path = require('path')
          image = [path.dirname(url), maybeImage.image].join('/')
        } else {
          if (url.indexOf('pinata') === -1) {
            // TODO url is another ipfs resource
            const maybeMetadata = (await getCFIPFS(getUrlPath(url))).data
            if (maybeMetadata.image.indexOf('ipfs') !== -1) {
              // use gateway url
              image = getCFIPFSUrl(getUrlPath(maybeMetadata.image))
            } else {
              // use url as is
              image = maybeMetadata.image
            }
          } else {
            // pinata url contains image
            image = url
          }
        }
      } else {
        // url may be gateway url
        image = url
      }
    } catch (e) {
      // use placeholder as fallback
      image = placeholderImage
    }
    return image
  }

export const getAssetImage = async (assetIndex) => {
  let image = await fetchAssetImage(assetIndex)
  console.log({ image })
  return image
}

/*
 * transactions
 */

export const getBidHistory = (stdlib, transactions, addr) =>
  ((transactions) =>
    transactions
      .filter(el => true
        && el['tx-type'] === 'pay'
        && el['payment-transaction'].receiver === addr)
      .map((el) => ({
        time: moment.unix(el['round-time']).format('LTS'),
        sender: el?.sender,
        amount: stdlib.formatCurrency(el["payment-transaction"]?.amount, 4)
      }))
      .slice(0, -3))
    (transactions)

/* 
* isAuctionOver
* if payment transactions platform address 
* then auction is over
*/
export const isAuctionOver = (transactions, addr) =>
  ((transactions =>
    transactions
      .map(el => el["payment-transaction"]?.receiver ?? "")))
    (transactions)
    .some(el => el === addr)


/*
 * fetchApp
 * given app id
 * returns object with id and escrow address
 */
export const fetchApp = async (appId) => {
  console.log(`Fetching app ${appId}`)
  const { transactions } = (await getAppTransactions(appId))?.data
  const [create, fund, ...rest] = transactions
  // use create tx as sender addr of constructor
  const sender = create.sender
  const fundConfirmedRound = fund['confirmed-round']
  const receiver = ((await getBlock(fundConfirmedRound))?.data?.transactions ?? [])
    .filter(el => el.sender === sender && el['tx-type'] === 'pay')[0]
  ['payment-transaction']['receiver']
  return ({
    id: appId,
    addr: receiver
  })
}

export const fetchState = async ({ id, addr, program }) => {
  console.log("Fetching state ...")
  let accInfo = (await getAccInfo(addr))?.data
  let amount = accInfo?.amount - accInfo['amount-without-pending-rewards']
  let rewards = accInfo?.rewards
  let asset = null
  let assetInfo = null
  let image = null
  if (accInfo?.assets?.length > 0) {
    asset = accInfo?.assets[0]
  }
  if (asset) {
    let assetId = asset['asset-id']
    // load asset info from storage
    let assetKey = `asset-${assetId}-info`
    let storedAssetInfo = localStorage.getItem(assetKey) 
    if(storedAssetInfo) {
      assetInfo = JSON.parse(storedAssetInfo)
    } else {
      assetInfo = (await getAsset2(assetId))?.data
      localStorage.setItem(assetKey, JSON.stringify(assetInfo))
    }
    // load image from storage
    let key = `asset-${assetId}-image`
    let storedImage = localStorage.getItem(key)
    if (storedImage) {
      image = JSON.parse(storedImage)
    } else {
      image = await getAssetImage(asset['asset-id'])
      localStorage.setItem(key, JSON.stringify(image))
    }
  }
  return ({
    id,
    addr,
    auctioneer: addr,
    asset: {
      ...asset,
      ...assetInfo
    },
    amount,
    rewards,
    image,
    program,
  })
}
/*
 * Escrow
 */
export const fetchEscrow = async (stdlib, acc, addr) => {
  console.log(`Fetching escrow '${addr}'`)
  const { data } = await getAccInfo(addr) // get escrow account info
  let status = data?.amount > 0 ? "live" : "closed"
  let amount = (((data?.amount ?? 200000) - 200000) / 1000000).toFixed(2)
  let asset = data?.assets[0]
  let image
  let assetId
  let assetInfo
  let transactions = (await getAccountTransaction(addr))?.data?.transactions ?? [] // get escro account transactions
  let firstTx = transactions[transactions.length - 1]['confirmed-round']
  let confirmedRound = transactions[0]['confirmed-round']
  let assetTxs = transactions.filter(el => el["asset-transfer-transaction"] && el["asset-transfer-transaction"]?.amount > 0)
  let payTxs = transactions.filter(el => el["tx-type"] === "pay")
  let bidTxs = payTxs.filter(el => el.sender !== addr && el['payment-transaction']?.amount > 100000)
  const zeroAddr = 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ'
  let auctioneer = assetTxs.map(el => el.sender).filter(el => el !== addr)
  let firstBlock = (((await getBlock(firstTx))?.data ?? {})?.transactions ?? []).filter(el => el.sender === auctioneer[0])
  let appId = parseInt((firstBlock[firstBlock.length - 1])['application-transaction']['application-id'])
  let ctc
  let startPrice
  let currentPrice
  let minBid
  let reservePrice
  let endSecs
  if (status === 'live') {
    try {
      console.log({ acc })
      ctc = await acc.contract(backend, appId)
      startPrice = formatCurrencyFromSome(stdlib, await ctc.v.Auction.startPrice())
      currentPrice = formatCurrencyFromSome(stdlib, await ctc.v.Auction.currentPrice())
      minBid = formatCurrencyFromSome(stdlib, await ctc.v.Auction.minBid())
      reservePrice = formatCurrencyFromSome(stdlib, await ctc.v.Auction.reservePrice())
      endSecs = bigNumberToNumberFromSome(stdlib, await ctc.v.Auction.endSecs())
    } catch (e) { }
    console.log({ ctc, firstBlock, appId, startPrice, currentPrice, minBid })
  }
  let senders = Array.from(new Set(payTxs.map(el => el.sender)))
  let lastTx
  console.log({ auctioneer })
  if (asset) {
    assetId = asset['asset-id']
  } else {
    assetId = assetTxs[0]["asset-transfer-transaction"]["asset-id"]
    console.log({ acc, assetId })
  }
  assetInfo = (await getAsset2(assetId))?.data
  image = await getAssetImage(assetId)
  let receiver = auctioneer
  let sold = false
  if (status === "closed") {
    lastTx = assetTxs.filter(el => el.sender === addr)[0]
    receiver = lastTx["asset-transfer-transaction"]?.receiver
    if (bidTxs.length > 0) {
      amount = bidTxs[0]['payment-transaction']?.amount / 1000000
    }
    sold = receiver !== auctioneer[0]
  }
  return ({
    amount: Math.floor(amount),
    addr,
    status,
    asset: {
      ...asset,
      ...assetInfo
    },
    image,
    transactions,
    assetTxs,
    payTxs,
    auctioneer,
    senders,
    receiver,
    lastTx,
    bidTxs,
    sold,
    confirmedRound,
    firstTx,
    firstBlock,
    appId,
    minBid,
    reservePrice,
    endSecs,
  })
}

const getAppUrlPrefix = (providerEnv) => {
  switch (providerEnv) {
    case 'MainNet':
      return 'a'
    case 'TestNet':
      return 't'
    default:
      return 'x'
  }
}
export const getAppUrl = (providerEnv) => (approvalProgram, type, appId) =>
  (prefix => `https://${prefix}${approvalProgram}.nftjam.net/${type}/${appId}`)
    (getAppUrlPrefix(providerEnv))


export const getAppSetupUrl = (providerEnv) => (approvalProgram, type, appId) =>
  (prefix => `https://${prefix}${approvalProgram}.nftjam.net/sell-${type}/${appId}`)
    (getAppUrlPrefix(providerEnv))

/* code from beegan */

// FORMAT CURRENCY

/**
 * Collection of functions for formatCurrency custom utility.
 * will eventually be moved to stdlib
 */
 function ldrop(str/*: string*/, char/*: string*/) {
  while (str[0] === char) {
    // eslint-disable-next-line no-param-reassign
    str = str.slice(1)
  }
  return str
}
function rdrop(str/*: string*/, char/*: string*/) {
  while (str[str.length - 1] === char) {
    // eslint-disable-next-line no-param-reassign
    str = str.slice(0, str.length - 1)
  }
  return str
}
function lpad(str/*: string*/, padChar/*: string*/, nChars/*: number*/) {
  const padding = padChar.repeat(Math.max(nChars - str.length, 0))
  return padding + str
}

export const formatCurrencyFromSome2 = (stdlib, some, decimal = 4) =>
  somethingFromSome((some) => formatCurrency(stdlib)(some, decimal), 0)(some)

export const formatCurrency = (stdlib) => (amt/*: any*/, decimals = 6)/*: string*/ => {
  if (!(Number.isInteger(decimals) && decimals >= 0)) {
    throw Error(
      `Expected decimals to be a nonnegative integer, but got ${decimals}.`,
    )
  }
  const amtStr = stdlib.bigNumberify(amt).toString()
  const splitAt = Math.max(amtStr.length - decimals, 0)
  const lPredropped = amtStr.slice(0, splitAt)
  const l = ldrop(lPredropped, '0') || '0'
  if (decimals === 0) {
    return l
  }
  const rPre = lpad(amtStr.slice(splitAt), '0', decimals)
  const rSliced = rPre.slice(0, decimals)
  const r = rdrop(rSliced, '0')

  return r ? `${l}.${r}` : l
}

// PARSE CURRENCY

/**
 *
 * @param {string|number} addr
 * @param {number} decimals (optional)
 * @returns big number to consumed by a reach program
 */
 export const getAmtForContract = (stdlib) => (
  amount,/*: string | number,*/
  decimals/*?: number,*/
) => {
  const numericAmt/*: number*/ = stdlib.isBigNumber(amount)
    ? amount.toNumber()
    : typeof amount === 'string'
    ? parseFloat(amount)
    : typeof amount === 'bigint'
    ? Number(amount)
    : amount
  const conUnit = getConUnit(decimals)
  const value = numericAmt * conUnit
  return stdlib.bigNumberify(Math.floor(value))
}

export const getConUnit = (decimals) => 10 ** decimals
